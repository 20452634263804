import { useEffect } from "react"
import Counter from "./Counter"
import { Helmet } from "react-helmet";
import usePageSeo from '../../../hooks/usePageSeo/index'


const About = () => {
    useEffect(() => { window.scrollTo(0, 0) }, [])

	usePageSeo({
		title: "Learn More About Us - The Team Behind Our Company",
		description: "Get to know the team behind our company and learn more about our history, values, and goals.",
		keyword: "Learn More About Us - The Team Behind Our Company",
		canonical: "https://mparchi.com/about",
		ogTitle: "My OG Title"

	})

    return (
        <>

{/*      
	   <Helmet>
	    <title>Learn More About Us - The Team Behind Our Company</title>
        <meta name="description" content="Get to know the team behind our company and learn more about our history, values, and goals. "/>
        <meta name="copyright" content="MPARCHI" />
        <meta name="Distribution" content="GLOBAL"/>
        <meta name="Format" content="text/html" />  
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mparchi.com/about" />
        <meta name="author" content="MPARCHI" />
        <link rel="alternate" hreflang="en-IN" href="https://mparchi.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Learn More About Us - The Team Behind Our Company" />
        <meta property="og:url" content="https://mparchi.com/about" />
        <meta property="og:image"content="https://mparchi.com/favicon.png" />
        <meta property="og:description" content="Get to know the team behind our company and learn more about our history, values, and goals." />   
	   </Helmet> */}
	 
            <div class="fancy-hero-two mt-90">
				<div class="bg-wrapper">
					<div class="container">
						<div class="page-title">About us</div>
						<div class="row">
							<div class="col-xl-10 col-lg-10 col-md-10 m-auto">
								<h1 class="heading">World best Billing, Quotation and Invoicing portal</h1>
								<p class="sub-heading">MParchi is a FREE Business Accounting Software built for Indian small businesses to deal with invoicing, inventory, accounting needs, and much more! The goal is to make a businessman’s daily routine less tiring and let them focus more on growing their business, less on paperwork.</p>
							</div>
						</div>
					</div>
				</div>
			</div> 

            <div class="fancy-text-block-nine mt-130 md-mt-80">
				<div class="shapes shape-one"></div>
				<div class="shapes shape-two"></div>
				<div class="shapes shape-three"></div>
				<div class="shapes shape-four"></div>
				<div class="container">
					<div class="title-style-two text-center mb-35 md-mb-10">
						<div class="row">
							<div class="col-lg-12 m-auto">
								<p>Our Story</p>
								<h2>The world’s first Conversational Relationship Platform</h2>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-xl-12 m-auto">
							<p class="text-meta" data-aos="fade-up" >
							At MParchi, we make managing engineering documents simple and efficient. Our platform is designed to help professionals in engineering and construction easily organize, find, and share technical drawings. With MParchi, you can streamline your workflow, reduce the risk of errors, and improve productivity. Our user-friendly interface and powerful features ensure that you can quickly access the information you need, keeping your projects on track. We also offer tools for secure collaboration, so everyone on your team stays updated and coordinated.We understand the complexities of engineering projects and aim to provide a reliable solution that meets your needs. 
							</p>

							<p class="text-meta" data-aos="fade-up" >
							MParchi supports seamless document upload and retrieval, making it easy to manage all your files in one place. Our focus is on delivering a hassle-free experience that allows you to concentrate on your work, knowing that your documents are well-organized and accessible.In addition to document management, we offer version control and secure sharing options, ensuring that your team has access to the most up-to-date information. This promotes better communication and efficient project management, helping you deliver successful projects.At MParchi, we are dedicated to continuous improvement and innovation, constantly enhancing our platform to meet the changing demands of the industry. We believe that the right tools can make a significant difference in the success of your projects, and we are committed to providing a solution that exceeds your expectations.
								</p>
						</div>
					</div>
				</div>
			</div>

            <Counter />

            <div class="fancy-text-block-ten mt-120 md-mt-60">
				<div class="container">
					<div class="row align-items-end">
						<div class="col-xl-8 col-lg-6 ml-auto order-lg-last" >
							<img src="assets/images/icon/34.svg" alt="" class="icon" />
							<p class="font-rubik">Experience the Milestone of financial management with MParchi – your consistent partner in your journey to success.</p>
							<div class="name sm-mb-40">MRS. RUPA KUMARI <span>Founder <br />MParchi</span></div>
						</div>
						<div class="col-lg-4 order-lg-first">
							<div class="row align-items-end">
								<div class="col-6" data-aos="fade-right" ></div>
								<div class="col-10" data-aos="fade-right" ><img src="assets/images/media/rupa-kumari.jpg" alt="" class="img-meta" /></div>
							</div>
						</div>
					</div>
					
				</div>
			</div>



            <div class="team-section-two mt-120 mb-120 md-mt-100">
				<div class="shapes shape-one"></div>
				<div class="shapes shape-two"></div>
				<div class="shapes shape-three"></div>
				<div class="shapes shape-four"></div>
				<div class="container">
					<div class="row align-items-center justify-content-between mb-80 md-mb-10">
						<div class="col-lg-8">
							<div class="title-style-two">
								<p>Our Team</p>
								<h2>Our talented team member waiting to server.</h2>
							</div>
						</div>
						<div class="col-lg-4 d-lg-flex justify-content-end">
							<a href="team-V1.html" class="theme-btn-three md-mt-20">See All Members</a>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-4 col-sm-6">
							<div class="team-member">
								<img src="assets/images/media/img_29.png" alt="" />
								<div class="name">Jhon Du</div>
								<div class="position">Senior Product Designer</div>
							</div>
						</div>
						<div class="col-lg-4 col-sm-6">
							<div class="team-member">
								<img src="assets/images/media/img_30.png" alt="" />
								<div class="name">Juan Barber</div>
								<div class="position">Co-Founder</div>
							</div>
						</div>
						<div class="col-lg-4 col-sm-6">
							<div class="team-member">
								<img src="assets/images/media/img_31.png" alt="" />
								<div class="name">Maud Ellis</div>
								<div class="position">Customer management</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>

        </>
    )
}

export default About
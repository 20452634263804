import React from "react";
import $ from "jquery";
import Slider from "react-slick";

import shape1 from './image/shape/39.svg'
import shape2 from './image/shape/42.png'
import mediaImg1 from './image/testimonial/img_21.png'
import mediaImg2 from './image/testimonial/img_22.png'
import mediaImg3 from './image/testimonial/img_23.png'
import mediaImg4 from './image/testimonial/img_24.png'
import QuotesIcon from './image/icon/30.svg'
import clientImg from './image/testimonial/img_20.png'

const Testimonial = () => {
    var settings = {
        dots: false,
        nav: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        arrows: true,
    };

    $(document).ready(function(){
        $(".clientSliderOne .slick-prev").html('<i className="fa-solid fa-angle-right"></i>')
        $(".clientSliderOne .slick-next").html('<i className="fa-solid fa-angle-left"></i>')
    })

    return (
        <>
            <div className="client-feedback-slider-one pt-150 pb-150">
                <div className="shapes-holder">
                    <img src={shape1} alt="" />
                    <img src={shape2} alt="" className="shapes shape-one" />
                    <img src={mediaImg1} alt="" className="cp-img-one" />
                    <img src={mediaImg2} alt="" className="cp-img-two" />
                    <img src={mediaImg3} alt="" className="cp-img-three" />
                    <img src={mediaImg4} alt="" className="cp-img-four" />
                    <div className="title-style-two">
                        <h2>What’s <br />Our Client Say <br />About Us.</h2>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 ml-auto">
                            <div className="feedback-meta">
                                <div className="watermark font-gilroy-bold">Testimonial</div>
                                <img src={QuotesIcon} alt="" className="icon" />

                                <Slider classNameName="clientSliderOne" {...settings}>
                                    <div>
                                        <div className="item">
                                            <p className="font-rubik">Having a home based business is a wonderful asset to your life. The problem still stands it comes timeadvertise your business for a cheap cost. I know you have looked answer everywhere.</p>
                                            <div className="d-lg-flex align-items-center">
                                                <img src={clientImg} alt="" className="c_img" />
                                                <div className="info">
                                                    <strong>Rashed kabir</strong> <span>Designer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="item">
                                            <p className="font-rubik">Having a home based business is a wonderful asset to your life. The problem still stands it comes timeadvertise your business for a cheap cost. I know you have looked answer everywhere.</p>
                                            <div className="d-lg-flex align-items-center">
                                                <img src={clientImg} alt="" className="c_img" />
                                                <div className="info">
                                                    <strong>Zubayer Hasan</strong> <span>Designer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial
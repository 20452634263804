import { Link } from 'react-router-dom'

const ContactInfo = () => {
    const contactCard = [
        {
            icon: <i class="fa-solid fa-envelope"></i>,
            head: 'Email',
            title: 'info@mparchi.com',
        },
        {
            icon: <i class="fa-solid fa-location-dot"></i>,
            head: 'Location',
            title: 'A - 1612 Tower 3, NX One Techzone 4,  Greater Noida - 201009',
        },
        {
            icon: <i class="fa-solid fa-phone"></i>,
            head: 'Mobile No',
            title: '+91 93112 21776',
        },
    ]
    return (
        <>
            <div class="contact-style-two">
                <div class="container">
                    <div class="contact-info-wrapper">
                        <div class="row justify-content-center">
                            {contactCard.map((item, index) => (
                                <div class="col-lg-4 col-sm-6 d-lg-flex"> 
                                    <div class="address-info">
                                        <div class="icon d-flex align-items-end justify-content-center">{item.icon}</div>
                                        <div class="title">{item.head}</div>
                                        <p class="font-rubik">
                                            {item.title}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img src="assets/images/shape/64.svg" alt="" class="shapes shape-one" />
                        <img src="assets/images/shape/65.svg" alt="" class="shapes shape-two" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactInfo
import { useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom'


export default function TabPricing() {
    useEffect(()=>{ window.scrollTo(0,0)},[])

    // const MonthlyData = [
    //     {
    //         plane: 'Free',
    //         Price: '0',
    //         features: ['Unlimited Invoices', 'Quotations & all documents', 'Inventory Items', 'View reports'],
    //     },
    //     {
    //         plane: 'Diamond',
    //         Price: '99',
    //         features: ['Unlimited Invoices', 'Quotations & all documents', 'Inventory Items', 'View reports', 'Create multiple companies', 'Create Multiple Firms', 'Balance Sheet', 'Stock transfer Report', 'Check Profit on Invoices'],
    //     },
    //     {
    //         plane: 'Platinum',
    //         Price: '299',
    //         features: ['Unlimited Invoices', 'Quotations & all documents', 'Inventory Items', 'View reports', 'Create multiple companies', 'Create Multiple Firms', 'Balance Sheet', 'Stock transfer Report', 'Check Profit on Invoices', 'Quick Billing', 'Manage godowns & Transfer stock', 'Item Serial Report', 'Item Batch Report'],
    //     },
    // ]

    const YearlyData = [
        {
            plane: 'Free',
            Price: '0',
            features: ['30 Days Free Trail'],
        },
        {
            plane: 'Silver Plan',
            Price: '1999',
            features: ['Items', 'Sales', 'Purchase', 'Quick Billing', 'Expense', 'Transaction Management', 'Data Driven Insights' ],
        },
        {
            plane: 'Gold Plan',
            Price: '2999',
            features: ['Items', 'Sales', 'Purchase', 'Quick Billing', 'Expense', 'Transaction Management', 'Data Driven Insights', 'HRMS' ],
        },
    ]

    return (
        <>

            <section className='pricing-section-two'>
                <div className="container">
                    <div className='pricing-table-area'>
                        <Tabs>
                            <TabList className="pricingTabList text-center">
                                {/* <Tab>Monthly</Tab> */}
                                <Tab>Yearly</Tab>
                            </TabList>

                            <div className='tab-content mt-50'>
                                {/* <TabPanel>
                                    <div className="row no-gutters">
                                        {MonthlyData.map((item, index) => (
                                            <div className="col-lg-4 col-sm-6 pr-bg d-flex">
                                                <div className="pr-table-wrapper">
                                                    <div className="pack-name">{item.plane}</div>
                                                    <div className="price"><sup>₹</sup>{item.Price}</div>
                                                    <Link to="#" className="theme-btn-three">Buy Now</Link>
                                                    <ul className="pr-feature">
                                                        {item.features.map((feature, i) => (
                                                            <li key={i}><i className="fa-solid fa-check"></i>{feature}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </TabPanel> */}
                                <TabPanel>
                                    <div className="row no-gutters">
                                        {YearlyData.map((item, index) => (
                                            <div className="col-lg-4 col-sm-6 pr-bg d-flex">
                                                <div className="pr-table-wrapper">
                                                    <div className="pack-name">{item.plane}</div>
                                                    <div className="price"><sup>₹</sup>{item.Price}</div>
                                                    <Link to="https://login.mparchi.com/signup" className="theme-btn-three">Buy Now</Link>
                                                    <ul className="pr-feature">
                                                        {item.features.map((feature, i) => (
                                                            <li key={i}><i className="fa-solid fa-check"></i>{feature}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div >
            </section>
        </>
    );
}

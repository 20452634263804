import { useEffect } from "react"
import TabPricing from "./TabPricing"
import { Helmet } from 'react-helmet'

const Pricing = () => {
    useEffect(()=>{ window.scrollTo(0,0)},[])
	
	return (
		<>
        <Helmet>
        <title>Affordable Architecture Services | MParchi - Our Price</title>
        <meta name="description" content="Explore MParchi's competitive pricing for top-quality architecture services. Our team offers affordable solutions for your projects. Contact us today. "/>
        <meta name="copyright" content="MPARCHI" />
        <meta name="Distribution" content="GLOBAL"/>
        <meta name="Format" content="text/html" />  
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mparchi.com/our-price" />
        <meta name="author" content="MPARCHI" />
        <link rel="alternate" hreflang="en-IN" href="https://mparchi.com/our-price" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Affordable Architecture Services | MParchi - Our Price" />
        <meta property="og:url" content="https://mparchi.com/our-price" />
        <meta property="og:image"content="https://mparchi.com/favicon.png" />
        <meta property="og:description" content="Explore MParchi's competitive pricing for top-quality architecture services. Our team offers affordable solutions for your projects. Contact us today." /> 
        </Helmet>
			<div className="pricing-section-two">
				<div className="fancy-hero-four">
					<div className="shapes shape-one"></div>
					<div className="shapes shape-two"></div>
					<div className="shapes shape-three"></div>
					<div className="shapes shape-four"></div>
					<div className="shapes shape-five"></div>
					<div className="shapes shape-six"></div>
					<div className="bg-wrapper">
						<div className="container">
							<div className="row">
								<div className="col-xl-10 col-lg-11 m-auto">
									<h2>Plans & Pricing</h2>
								</div>
								<div className="col-12 m-auto">
									<p className="font-rubik sub-heading">Choose a plan that suits for your business</p>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>

			<TabPricing />


		</>
	)
}

export default Pricing
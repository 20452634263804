const TailoredSection = () => {
    const cardData = [
        {
            icon: <i className="fa-solid fa-cloud-arrow-up"></i>,
            head: "Cloud Storage",
            title: "Your Cloud Storage, Simplified and Secure.",
        },
        {
            icon: <i className="fa-solid fa-list-check"></i>,
            head: "Policies",
            title: "Policies Made Simple: Clear, Fair, and Easy to Follow.",
        },
        {
            icon: <i className="fa-solid fa-tv"></i>,
            head: "Updation",
            title: "Seamless Updates: Always Current, Always Reliable with Mparchi.",
        },
        {
            icon: <i className="fa-solid fa-people-group"></i>,
            head: "Community",
            title: "Be a part of mparchi community, where connection and growth happen.",
        },
    ]
    return (
        <>
            <div className="fancy-feature-ten mt-100 md-mt-70 lg-container " id="feature">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className=" aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200">
                                <div className="">
                                    {/* <h6 classNameName="mb-20"><b>Your personal marketing & sales assistant, right in your pocket - Billing software with marketing capabilities</b></h6> */}
                                    {/* <h3>A lot more than you can imagine from a m-Parchi software</h3> */}
                                    <h2>Simplify your billing with ease.</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-35 md-mt-20">
                            {cardData.map((item, index) => (
                                <div className="col-lg-3 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="block-style-fifteen mt-40">
                                        <div className="icon tailer-card d-flex align-items-center justify-content-center">{item.icon}</div>
                                        <h3>{item.head}</h3>
                                        <p>{item.title}</p>
                                        <a href="#" className="mt-30"><i className="fa-solid fa-arrow-right-long"></i></a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <img src="images/shape/137.svg" alt="" className="shapes shape-one" />
                </div>
            </div>
        </>
    )
}
export default TailoredSection
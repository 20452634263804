import { useEffect } from "react"

const usePageSeo = ({
    title,
    description,
    keyword,
    ogTitle
    
})=> {

useEffect(() =>{
    document.title = title    
    setMetaTag('name', 'description', description)
    setMetaTag('name', 'keyword', keyword)
    setMetaTag('property', 'og:title', ogTitle || title)
    return() => {
    }

},[title,
description,
keyword,
ogTitle])

const setMetaTag =(attr, key, content) => {
 if(content) {
    let element = document.querySelector(`meta[${attr}="${key}"]`)
    if (!element){
        element = document.createElement('meta')
        element.setAttribute(attr, key)
        document.head.appendChild(element)
    }
    element.setAttribute('content', content)
 }
}
}
 
export default usePageSeo
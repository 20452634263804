import { useEffect } from "react"
import { Helmet } from 'react-helmet'

const All_features = () => {
    useEffect(()=>{ window.scrollTo(0,0)},[])

    return (      

        <>

<Helmet>
        <title>Explore the Top Features of MParchi - The Ultimate Architecture Software</title>
        <meta name="description" content="Explore advanced features, intuitive tools, and seamless collaboration for unparalleled efficiency. Try it now! "/>
        <meta name="copyright" content="MPARCHI" />
        <meta name="Distribution" content="GLOBAL"/>
        <meta name="Format" content="text/html" />  
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mparchi.com/features" />
        <meta name="author" content="MPARCHI" />
        <link rel="alternate" hreflang="en-IN" href="https://mparchi.com/features" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Explore the Top Features of MParchi - The Ultimate Architecture Software" />
        <meta property="og:url" content="https://mparchi.com/features" />
        <meta property="og:image"content="https://mparchi.com/favicon.png" />
        <meta property="og:description" content="Explore advanced features, intuitive tools, and seamless collaboration for unparalleled efficiency. Try it now!" /> 
        </Helmet>

            <div class="fancy-hero-one">
				<div class="container">
					<div class="row">
						<div class="col-xl-10 m-auto">
							<h2 class="font-rubik">Get effective Features for your business</h2>
						</div>
						<div class="col-xl-9 m-auto">
							<p class="font-rubik">With MParchi, get all kind of business solution to start your journey</p>
							<a href="" class="theme-btn-one btn-lg mt-50 md-mt-30">Contact US</a>
						</div>
					</div>
				</div>
				<div class="bubble-one"></div>
				<div class="bubble-two"></div>
				<div class="bubble-three"></div>
				<div class="bubble-four"></div>
				<div class="bubble-five"></div>
				<div class="bubble-six"></div>
			</div>

			<div class="fancy-text-block-four pt-130 pb-120 md-pt-100 md-pb-80">
				<div class="container">
					<div class="row">
						<div class="col-xl-7 col-lg-8">
							<div class="title-style-one mb-40 md-mb-20">
								<h2>For all kinds of Teams</h2>
								<p class="font-rubik">Duis aute irure dolor in reprehenderit in voluptate velit io cillum dolore eu fugiat nulla pariatur non labrum.</p>
							</div> 
						</div>
					</div>

					<div class="wrapper">
						<div class="row justify-content-center">
							<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
								<div class="block-style-three">
									<div class="icon"><img src="assets/images/icon/09.svg" alt="" /></div>
									<h4>Project management</h4>
									<p class="font-rubik">tempor incididunt ut labor culpa dolore magna aliqua. Ut enim qui minim veniam, </p>
								</div> 
							</div>
							<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
								<div class="block-style-three">
									<div class="icon"><img src="assets/images/icon/10.svg" alt="" /></div>
									<h4>Customer Support</h4>
									<p class="font-rubik">Excepteur sint occaecat cupidatat non proident, sunt in culpa non officia.</p>
								</div> 
							</div>
							<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
								<div class="block-style-three">
									<div class="icon"><img src="assets/images/icon/11.svg" alt="" /></div>
									<h4>Marketing</h4>
									<p class="font-rubik">consectetur adipiscing elit, sed dou eiusmod tempor incididu ut lab et dolore.</p>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="fancy-text-block-five pt-130 pb-160 md-pt-100 md-pb-100">
				<div class="container">
					<div class="row">
						<div class="col-lg-7 ml-auto">
							<div class="title-style-one mb-50 md-mb-30">
								<h2>For all kinds of Startup</h2>
								<p class="font-rubik">Excepteur sint occaecat cupidat non proident, sunt in culpa qui officia deserunt mollit anim est laborum.</p>
							</div> 
						</div>
					</div>

					<div class="wrapper">
						<div class="row justify-content-center">
							<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
								<div class="block-style-four">
									<div class="icon"><img src="assets/images/icon/12.svg" alt="" /></div>
									<h4>Media & Hospital</h4>
									<p class="font-rubik">Lorem ipsum dolor amet, consetre adipiscing elit, sed do eiusmod tempor incididunt ut labore et dole magna aliqua. Ut enim </p>
									<a href="#"><i class="flaticon-right-arrow"></i></a>
								</div> 
							</div>
							<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
								<div class="block-style-four">
									<div class="icon"><img src="assets/images/icon/13.svg" alt="" /></div>
									<h4>Educational</h4>
									<p class="font-rubik">Lorem ipsum dolor amet, consetre adipiscing elit, sed do eiusmod tempor incididunt ut labore et dole magna aliqua. Ut enim </p>
									<a href="#"><i class="flaticon-right-arrow"></i></a>
								</div> 
							</div>
							<div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-duration="1200">
								<div class="block-style-four">
									<div class="icon"><img src="assets/images/icon/14.svg" alt="" /></div>
									<h4>Financial Service</h4>
									<p class="font-rubik">Lorem ipsum dolor amet, consetre adipiscing elit, sed do eiusmod tempor incididunt ut labore et dole magna aliqua. Ut enim </p>
									<a href="#"><i class="flaticon-right-arrow"></i></a>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>

        
        </>
    )
}

export default All_features



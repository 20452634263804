import { useEffect } from "react"

const Terms_and_conditions = () => {
    useEffect(()=>{ window.scrollTo(0,0)},[])

    return (      

        <>
       		<div class="terms_and_policy">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">							
							<div class="tab-content">								
								<div>
									<h2 class="font-gilroy-bold">Terms & Conditions</h2>
									<div class="update-date">LAST UPDATED: 6/26/2020</div>
									<h3>1. Introduction</h3>
									<p>This page, together with our Privacy Policy and the Website Terms of Use tells you information about us and the legal terms and conditions (the "Terms") on which we make available to you our online ticket-selling solution (the "Service").</p>
									<h3>2. Information about us</h3>
									<p>Tickettailor.com and buytickets.at and any of their sub-domains is a site operated by Ticket Tailor, which is the trading name of Zimma Ltd ("we", “our”, “us”). We are registered in England and Wales under company number 07583551 and have our registered office at Unit 6, 14A Andre St, London, E8 2AA. Our VAT number is GB184622203.</p>
									<p>You can contact us by emailing <a href="#">support@mparchi.com</a>.</p>
								</div>								
							</div> 
						</div>
					</div>
				</div>
			</div>

        
        </>
    )
}

export default Terms_and_conditions



import React, { useEffect, useState } from 'react';
import logo from './images/ebooklogo.png';
import { Link } from 'react-router-dom'

const Footer = () => {
	const [scrollTopVisible, setScrollTopVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setScrollTopVisible(window.scrollY > 200);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<>
<div class="fancy-short-banner-fourteen md-mt-80">
				<div class="container">
					<div class="row">
						<div class="col-12" data-aos="fade-up" data-aos-duration="1200">
							<div class="title-style-thirteen">
								<h2>200k+ Customers <br/> have our MParchi. Try it now!</h2>
							</div>
							<p>Try it risk free — we don’t charge cancellation fees.</p>
							<a href="" class="theme-btn-twelve">Let’s Get Started</a>
						</div>
					</div>
				</div> 
				<img src="assets/images/shape/239.svg" alt="" class="shapes shape-one" />
				<img src="assets/images/shape/240.svg" alt="" class="shapes shape-two" />
				<img src="assets/images/shape/232.svg" alt="" class="shapes shape-three" />
				<img src="assets/images/shape/233.svg" alt="" class="shapes shape-four" />
				<img src="assets/images/shape/234.svg" alt="" class="shapes shape-five" />
				<img src="assets/images/shape/235.svg" alt="" class="shapes shape-six" />
				<img src="assets/images/shape/236.svg" alt="" class="shapes shape-seven" />
				<img src="assets/images/shape/232.svg" alt="" class="shapes shape-eight" />
		</div>


<footer class="theme-footer-seven mt-120 md-mt-100">
				<div class="lg-container">
					<div class="container">
						<div class="row">
							<div class="col-xl-3 col-lg-2 mb-40 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
								<div class="logo"><img src={logo} alt="MParchi Logo" /></div>
							</div>
							<div class="col-lg-2 col-md-6 mb-40 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
								<h5 class="title">Links</h5>
								<ul class="footer-list">
									<li><a href="#">Home</a></li>
									<li><Link to="/about">About us</Link></li>
									<li><Link to="/our-price">Pricing</Link></li>									
									<li><a href="#">Features</a></li>
								</ul>
							</div>
							<div class="col-lg-3 col-md-6 mb-40 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
								<h5 class="title">Legal</h5>
								<ul class="footer-list">
									<li><a href="/terms-of-use">Terms of use</a></li>
									<li><a href="/terms-and-conditions">Terms &amp; conditions</a></li>
									<Link to="/privacy-policy">Privacy Policy </Link>
									<li><a href="#">Careers</a></li>
								</ul>
							</div>
							<div class="col-xl-4 col-lg-5 mb-40 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
								<div class="newsletter">
									<h5 class="title">Newslettert</h5>
									<p>Join over <span>68,000</span> people getting our emails</p>
									<form action="#">
										<input type="email" placeholder="Enter your email" />
										<button class="dark-btn">Sign Up</button>
									</form>
									<div class="info">We only send interesting and relevant emails.</div>
								</div> 
							</div>
						</div>
					</div>

					<div class="container">
						<div class="bottom-footer">
							<div class="row">
								<div class="col-lg-2 order-lg-1 mb-20">
									<ul class="d-flex justify-content-center justify-content-lg-start footer-nav">	
									<Link to="/contact">Contact Us</Link>
									</ul>
								</div>
								<div class="col-lg-2 order-lg-3 mb-20">
									<ul class="d-flex justify-content-center justify-content-lg-end social-icon">
										<li><a href="https://www.facebook.com/people/MParchi/61562710567931/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
										<li><a href="https://www.instagram.com/mparchiofficial/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
									</ul>
								</div>
								<div class="col-lg-8 order-lg-2 mb-20">
									<p class="copyright text-center">© 2024 MParchi. All Rights Reserved. Designed & Development by MNB Soft Solution Pvt. Ltd</p>
								</div>
							</div>
						</div>
					</div>
				</div> 				
			</footer>		

			{scrollTopVisible && (
				<button className="scroll-top" onClick={scrollToTop} style={{ display: "block" }}>
					<i className="fa fa-angle-up" aria-hidden="true"></i>
				</button>
			)}
		</>
	);
};

export default Footer;

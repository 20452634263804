import NumberCounter from 'number-counter';

const Counter = () => {
    return (
        <>
            <div class="counter-with-icon-one border-style pt-120 pb-45 md-pt-70">
                <div class="container">
                    <div class="border-style">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                                <div class="counter-box-three">
                                    <div class="icon"><i class="fa-solid fa-download"></i></div>
                                    <h2 class="number"><NumberCounter end={5000} delay={3} className="increment" preFix="" postFix="K" /></h2>
                                    <p class="font-rubik">Downloads</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                                <div class="counter-box-three">
                                    <div class="icon"><i class="fa-solid fa-receipt"></i></div>
                                    <h2 class="number"><NumberCounter end={10000} delay={5} className="increment" preFix="" postFix="K" /></h2>
                                    <p class="font-rubik">Invoice Created</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
                                <div class="counter-box-three">
                                    <div class="icon"><i class="fa-solid fa-earth-americas"></i></div>
                                    <h2 class="number"><NumberCounter end={50} delay={5} className="increment" preFix="" postFix="+" /></h2>
                                    <p class="font-rubik">Countries</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Counter
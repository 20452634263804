import { useEffect } from "react"

const Terms_of_use = () => {
    useEffect(()=>{ window.scrollTo(0,0)},[])

    return (      

        <>
       		<div class="terms_and_policy">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">							
							<div class="tab-content">
								<div id="opt1" class="tab-pane fade active show">
									<h2 class="font-gilroy-bold">Website Terms & Use</h2>
									<div class="update-date">LAST UPDATED: 6/26/2020</div>
									<h3>1. Introduction</h3>
									<p>We have taken a number of steps to comply with the GDPR. For more details about our compliance and how we handle your data please check our latest legal documents below:</p>
									<ul>
										<li><a href="#">Privacy policy</a></li>
										<li><a href="#">Terms and conditions</a></li>
									</ul>
									<h3>2. About us</h3>
									<p>When you use Ticket Tailor for selling tickets and collecting data from your attendees you are the “data controller” of all your attendees’ data. We are a “data processor”, which means that we won’t do anything with your attendees’ data other than what you need us to in order to provide our service. We won’t be sending your attendees any marketing emails, sharing their data with anyone and if you want us to delete the data, just ask. It’s your data. 100</p>
									<h3>3. Accessing our Website</h3>
									<p>The servers and databases that power Ticket Tailor are located in the EU in Ireland. We inevitably use a small number of third parties, some of which are outside of the EU, to provide our ticketing service and we have contracts with each of them that oblige them to comply with the GDPR. You can find the full list of these third parties and what we use them for here.</p>
								</div>
							</div> 
						</div>
					</div>
				</div>
			</div>

        
        </>
    )
}

export default Terms_of_use



import { useEffect } from "react"
import { Helmet } from 'react-helmet'

const How_it_work = () => {
    useEffect(()=>{ window.scrollTo(0,0)},[])

    return (      

        <>

<Helmet>
        <title>How It Works: A Comprehensive Overview of the Process</title>
        <meta name="description" content="Discover the inner workings of various processes and gain a comprehensive understanding of how things get done with our detailed guide on how it works. "/>
        <meta name="copyright" content="MPARCHI" />
        <meta name="Distribution" content="GLOBAL"/>
        <meta name="Format" content="text/html" />  
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mparchi.com/how-it-work" />
        <meta name="author" content="MPARCHI" />
        <link rel="alternate" hreflang="en-IN" href="https://mparchi.com/how-it-work" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="How It Works: A Comprehensive Overview of the Process" />
        <meta property="og:url" content="https://mparchi.com/how-it-work" />
        <meta property="og:image"content="https://mparchi.com/favicon.png" />
        <meta property="og:description" content="Discover the inner workings of various processes and gain a comprehensive understanding of how things get done with our detailed guide on how it works." /> 
        </Helmet>
       		
			   <div class="fancy-hero-three bg-transparent">
				<div class="shapes shape-one"></div>
				<div class="shapes shape-two"></div>
				<div class="shapes shape-three"></div>
				<div class="shapes shape-four"></div>
				<div class="shapes shape-five"></div>
				<div class="shapes shape-six"></div>
				<div class="bg-wrapper">
					<div class="container">
						<div class="row">
							<div class="col-xl-9 col-lg-10 col-md-10 m-auto">
								<h1 class="heading">Check Our Product How It Work</h1>
								<p class="sub-heading">MParchi helps teams of all sizes get better at delivering effortless customer</p>
								<a href="#" class="theme-btn-two trial-button mt-50 md-mt-30">Start Trial</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			

			<div class="fancy-feature-seven">
				<div class="container">
					<div class="title-style-two text-center mb-150 md-mb-70">
						<p>Our Product</p>
						<h2>First-timer? Or old-hand? provide support with Mparchi is simple.
						</h2>
					</div> 

					<div class="block-wrapper">
						<div class="block-style-nine">
							<div class="row align-items-center">
								<div class="col-lg-7 col-md-9 m-auto" data-aos="fade-right" data-aos-duration="1200">
									<div class="illustration-holder">
										<img src="assets/images/ils_04.svg" alt="" />
									</div> 
								</div>
								<div class="col-lg-5" data-aos="fade-left" data-aos-duration="1200">
									<div class="text-wrapper">
										<h6>Create Event</h6>
										<h3 class="title font-gilroy-bold">Provide your support now simple and easy.</h3>
										<p class="font-rubik">Set-up a simple, professional box-office page on your site (or standalone) in minutes. Then design and customise it as much (or as little) as you like with our user-friendly dashboard</p>
									</div> 
								</div>
							</div>
						</div> 

						<div class="block-style-nine">
							<div class="row align-items-center">
								<div class="col-lg-7 col-md-9 m-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
									<div class="illustration-holder">
										<img src="assets/images/ils_05.svg" alt="" />
									</div> 
								</div>
								<div class="col-lg-5 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
									<div class="text-wrapper">
										<h6>SELL TICKET</h6>
										<h3 class="title font-gilroy-bold">Sell tickets anywhere anytime online.</h3>
										<p class="font-rubik">Run your box office as it’s own website, embed it into your website with a simple piece of HTML or widget, or put it on your Facebook page*. All with a reliable, mobile-friendly design.</p>
									</div> 
								</div>
							</div>
						</div> 

						<div class="block-style-nine">
							<div class="row align-items-center">
								<div class="col-lg-7 col-md-9 m-auto" data-aos="fade-right" data-aos-duration="1200">
									<div class="illustration-holder">
										<img src="assets/images/ils_06.svg" alt="" />
									</div> 
								</div>
								<div class="col-lg-5" data-aos="fade-left" data-aos-duration="1200">
									<div class="text-wrapper">
										<h6>management</h6>
										<h3 class="title font-gilroy-bold">Manage client easily by a simple click.</h3>
										<p class="font-rubik">Set-up a simple, professional box-office page on your site (or standalone) in minutes. Then design & customise it as much (or as little) as you like with our user-friendly dashboard.</p>
									</div> 
								</div>
							</div>
						</div> 

						<div class="block-style-nine">
							<div class="row align-items-center">
								<div class="col-lg-7 col-md-9 m-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
									<div class="illustration-holder">
										<img src="assets/images/ils_07.svg" alt="" />
									</div> 
								</div>
								<div class="col-lg-5 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
									<div class="text-wrapper">
										<h6>Dashboard</h6>
										<h3 class="title font-gilroy-bold">All the data & info for your need.</h3>
										<p class="font-rubik">You decide what data you want from your guests – and it’s your data. We don’t see or touch it. Understand how and who you’re selling to, with ticket sale notifications, and easy-to-understand sales summaries and customer order.</p>
									</div> 
								</div>
							</div>
						</div> 
					</div>
				</div>
			</div> 


			<div class="fancy-text-block-thirteen pt-140 md-pt-100">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
							<div class="text-wrapper">
								<div class="title-style-two">
									<h2>Security you can trust easily.</h2>
								</div> 
								<p>Our dashboard is served over SSL which means you can be sure that all data transferred is secure. Payment processing uses the very latest PCI DSS card-security standards</p>
							</div>
						</div>
						<div class="col-lg-6 col-md-8 m-auto order-lg-first" data-aos="fade-right" data-aos-duration="1200">
							<img src="assets/images/feature-img-11.png" alt="" />
						</div>
					</div>
				</div>
			</div> 


			<div class="useable-tools-section-two bg-shape mb-250 mt-150 md-mt-100 md-mb-100">
				<div class="bg-wrapper">
					<div class="shapes shape-one"></div>
					<div class="shapes shape-two"></div>
					<div class="shapes shape-three"></div>
					<div class="shapes shape-four"></div>
					<div class="container">
						<div class="title-style-two text-center mb-70 md-mb-10">
							<div class="row">
								<div class="col-lg-10 col-md-11 m-auto">
									<p>Integrates with your tools</p>
									<h2>Connect deski with the software you
										<span>use every<img src="assets/images/shape/line-shape-2.svg" alt="" /></span>
										day.
									</h2>
								</div>
							</div>
						</div> 

						<div class="icon-wrapper">
							<ul class="clearfix">
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/09.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/10.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/11.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/12.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/13.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/14.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/15.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/16.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/17.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/18.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/19.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/20.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/21.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/22.png" alt="" />
									</div>
								</li>
								<li>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/23.png" alt="" />
									</div>
									<div class="icon-box d-flex align-items-center justify-content-center">
										<img src="assets/images/logo/24.png" alt="" />
									</div>
								</li>
							</ul>
						</div> 
					</div> 
				</div> 
			</div> 

			<div class="fancy-text-block-twentyThree lg-container mb-100 md-mt-90">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 order-lg-last" data-aos="fade-left">
							<div class="text-wrapper pl-xl-5">
								<div class="cl-text">Over <span>150,000+</span> client</div>
								<div class="title-style-twelve">
									<h2>Signture that work Smoothly.</h2>
									<p class="mt-35 mb-60 md-mb-40">Commonly used in the graphic, prit quis due & publishing indust for previewing lightly visual mockups.</p>
								</div>
								<a href="#" class="theme-btn-eight">Start Free Trial</a>
							</div>
						</div>
						<div class="col-lg-6" data-aos="fade-right">
							<div class="img-container position-relative md-mt-60">
								<img src="assets/images/screen_30.png" alt="" class="main-screen" />
								<img src="assets/images/screen_31.png" alt="" class="screen-one" />
							</div>
						</div>
					</div>
				</div>

				<div class="mt-90 md-mt-50">
					<div class="container">
						<div class="row justify-content-between">
							<div class="col-xl-3 col-md-4" data-aos="fade-up">
								<div class="block-style-twentyEight text-center mt-40">
									<div class="icon d-flex justify-content-center align-items-end"><img src="assets/images/icon/149.svg" alt="" /></div>
									<h4>Sign Doc Anywhere</h4>
									<p>Deski Web offers a complete lineup of features from any major browser.</p>
								</div> 
							</div>
							<div class="col-xl-3 col-md-4" data-aos="fade-up" data-aos-delay="100">
								<div class="block-style-twentyEight text-center mt-40">
									<div class="icon d-flex justify-content-center align-items-end"><img src="assets/images/icon/150.svg" alt="" /></div>
									<h4>Simple to use</h4>
									<p>Lorem ipsum began as scrambl nonsensical Latin derived from Cicero's quis</p>
								</div> 
							</div>
							<div class="col-xl-3 col-md-4" data-aos="fade-up" data-aos-delay="200">
								<div class="block-style-twentyEight text-center mt-40">
									<div class="icon d-flex justify-content-center align-items-end"><img src="assets/images/icon/151.svg" alt="" /></div>
									<h4>Save time & Effort</h4>
									<p>Creation timelines for the standard lorem ipsum passage vary, with qius some citing.</p>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>

        
        </>
    )
}

export default How_it_work



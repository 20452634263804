import { Link } from 'react-router-dom'
import ContactForm from './ContactForm'
import ContactInfo from './ContactInfo'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const Contact = () => {
    useEffect(()=>{ window.scrollTo(0,0)},[])
    
    return (
        <>

        <Helmet>
        <title>Contact Us Today for All Your Web Design and Development Needs | Custom Digital Solutions</title>
        <meta name="description" content="Get in touch with our team of expert web designers and developers for high-quality custom digital solutions. Contact us today to bring your online presence to the next level. "/>
        <meta name="copyright" content="MPARCHI" />
        <meta name="Distribution" content="GLOBAL"/>
        <meta name="Format" content="text/html" />  
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mparchi.com/contact" />
        <meta name="author" content="MPARCHI" />
        <link rel="alternate" hreflang="en-IN" href="https://mparchi.com/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us Today for All Your Web Design and Development Needs | Custom Digital Solutions" />
        <meta property="og:url" content="https://mparchi.com/contact" />
        <meta property="og:image"content="https://mparchi.com/favicon.png" />
        <meta property="og:description" content="Get in touch with our team of expert web designers and developers for high-quality custom digital solutions. Contact us today to bring your online presence to the next level." /> 
        </Helmet>
            <div class="fancy-hero-four space-fix">
                <div class="shapes shape-one"></div>
                <div class="shapes shape-two"></div>
                <div class="shapes shape-three"></div>
                <div class="shapes shape-four"></div>
                <div class="shapes shape-five"></div>
                <div class="shapes shape-six"></div>
                <div class="bg-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
                                <h2>Contact us</h2>
                                {/* <h2>Feel free to contact us or just say hi!</h2> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ContactInfo />

            <ContactForm />
        </>
    )
}

export default Contact
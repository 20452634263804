import TailoredSection from "./TailoredSection";
import Testimonial from "./Testimonial";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import usePageSeo from "../../../hooks/usePageSeo";

const Home = () => {
	usePageSeo({
		title: "Simplify Office Expense Management with MParchi - Say Goodbye to Tedious Processes!",
		description: "MParchi is the ultimate solution for managing your office expenses efficiently. Say goodbye to manual processes and keep track of your expenses easily. Try MParchi now!",
		keyword: "MPARCHI",
		ogTitle: "Simplify Office Expense Management with MParchi - Say Goodbye to Tedious Processes!"

	})
  return (
    <>
      
	  {/* <div>
	   <Helmet>
		<title>Simplify Office Expense Management with MParchi - Say Goodbye to Tedious Processes!</title>
        <meta name="description" content="MParchi is the ultimate solution for managing your office expenses efficiently. Say goodbye to manual processes and keep track of your expenses easily. Try MParchi now! "/>
        <meta name="copyright" content="MPARCHI" />
        <meta name="Distribution" content="GLOBAL"/>
        <meta name="Format" content="text/html" />  
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://mparchi.com/" />
        <meta name="author" content="MPARCHI" />
        <link rel="alternate" hreflang="en-IN" href="https://mparchi.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simplify Office Expense Management with MParchi - Say Goodbye to Tedious Processes!" />
        <meta property="og:url" content="https://mparchi.com/" />
        <meta property="og:image"content="https://mparchi.com/favicon.png" />
        <meta property="og:description" content="MParchi is the ultimate solution for managing your office expenses efficiently. Say goodbye to manual processes and keep track of your expenses easily. Try MParchi now!" />  
	   </Helmet>
	  </div> */}

      <div class="hero-banner-twelve lg-container">
				<div class="container">
					<div class="row">
						<div class="col-xl-10 col-lg-12 col-md-10 m-auto text-center">
							<h1 class="hero-heading font-gordita">
							Revolutionize Your <span class="text-color1">Finances</span> with Our <span class="text-color2">All-in-One Billing</span> and <span class="text-color1">Invoicing</span> Mastery.
							</h1>
							<p class="hero-sub-heading">MParchi is the ultimate solution for managing your office expenses efficiently. Say goodbye to manual processes and keep track of your expenses easily. Try MParchi now!</p>
							<Link className="get-start-btn" to="https://login.mparchi.com/signup">Get Started<i class="fa fa-angle-right ml-1"></i></Link>

						</div>
					</div>

					<div class="screen-holder">
						<img src="assets/images/screen_32.png" alt="" class="img-meta" />
						<img src="assets/images/screen_33.png" alt="" class="shapes screen-one" />
						<img src="assets/images/screen_34.png" alt="" class="shapes screen-two" />
						<img src="assets/images/shape/230.svg" alt="" class="shapes shape-one" />
						<img src="assets/images/shape/231.svg" alt="" class="shapes shape-two" />
					</div>
				</div>
				<img src="assets/images/shape/232.svg" alt="" class="shapes shape-three" />
				<img src="assets/images/shape/233.svg" alt="" class="shapes shape-four" />
				<img src="assets/images/shape/234.svg" alt="" class="shapes shape-five" />
				<img src="assets/images/shape/235.svg" alt="" class="shapes shape-six" />
				<img src="assets/images/shape/236.svg" alt="" class="shapes shape-seven" />
				<img src="assets/images/shape/232.svg" alt="" class="shapes shape-eight" />
			</div>

      <div class="counter-style-three lg-container mt-130">
				<div class="container">
					<div class="bottom-border">
						<div class="row">
							<div class="col-lg-4">
								<div class="counter-box-five text-center mb-65" data-aos="fade-up">
									<div class="dot" style={{background: "#25E5DA"}}></div>
									<h2 class="number font-gordita"><span class="timer" data-from="0" data-to="200" data-speed="1500" data-refresh-interval="5">200</span>+</h2>
									<p>Happy Customers</p>
								</div> 
							</div>
							<div class="col-lg-4">
								<div class="counter-box-five text-center mb-65" data-aos="fade-up" data-aos-delay="100">
									<div class="dot" style={{background: "#FF36AF"}}></div>
									<h2 class="number font-gordita">Free</h2>
									<p>Android Mobile App</p>
								</div> 
							</div>
							<div class="col-lg-4">
								<div class="counter-box-five text-center mb-65" data-aos="fade-up" data-aos-delay="150">
									<div class="dot" style={{background: "#FFB13A"}}></div>
									<h2 class="number font-gordita">Multi-Device</h2>
									<p>Use together on Mobile/Desktop</p>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>

      <div class="fancy-feature-seventeen lg-container mt-150 md-mt-90" id="feature">
				<div class="container">
					<div class="row">
						<div class="col-lg-10 m-auto">
							<div class="title-style-thirteen text-center mb-130 md-mb-90">
								<div class="upper-title">Features</div>
								<h2>All-in-One Billing and Accounting Solutions with Key Features for Seamless Operations.
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="bottom-border pb-50">
						<div class="row">
							<div class="col-lg-4 col-sm-6">
								<div class="block-meta">
									<div class="icon d-flex align-items-end">
										<img src="assets/images/icon-img/item.png"  style={{width: "50px"}} alt="" /></div>
									<h4>Items</h4>
									<p>Handle items easily with Mparchi. Quickly add, edit, and track your inventory. Get real-time updates and keep everything organized effortlessly.</p>
								</div> 
							</div>
							<div class="col-lg-4 col-sm-6">
								<div class="block-meta">
									<div class="icon d-flex align-items-end">
									<img src="assets/images/icon-img/sale.png"  style={{width: "50px"}} alt="" /></div>
									<h4>Sales</h4>
									<p>Boost your sales with Mparchi. Easily manage transactions, track performance, and streamline your sales process for better results.</p>
								</div> 
							</div>
							<div class="col-lg-4 col-sm-6">
								<div class="block-meta">
									<div class="icon d-flex align-items-end">
									<img src="assets/images/icon-img/checklist.png"  style={{width: "50px"}} alt="" /></div>
									<h4>Purchase</h4>
									<p>Streamline your purchases with Mparchi. Effortlessly manage orders and keep track of inventory, simplifying your procurement process.</p>
								</div> 
							</div>
							<div class="col-lg-4 col-sm-6">
								<div class="block-meta">
									<div class="icon d-flex align-items-end">
									<img src="assets/images/icon-img/bill.png"  style={{width: "50px"}} alt="" /></div>
									<h4>Quick Billing</h4>
									<p>Quick billing with Mparchi is a breeze. Create and send invoices fast, with minimal effort. Keep track of payments and manage billing details easily.</p>
								</div> 
							</div>
							<div class="col-lg-4 col-sm-6">
								<div class="block-meta">
									<div class="icon d-flex align-items-end">
									<img src="assets/images/icon-img/accounting.png"  style={{width: "50px"}} alt="" /></div>
									<h4>Expense</h4>
									<p>Manage your expenses effortlessly with Mparchi. Track spending, categorize costs, and monitor your budget with ease. Stay on top of your financials and make informed decisions quickly.</p>
								</div> 
							</div>
							<div class="col-lg-4 col-sm-6">
								<div class="block-meta">
									<div class="icon d-flex align-items-end">
									<img src="assets/images/icon-img/hrms.png"  style={{width: "50px"}} alt="" /></div>
									<h4>HRMS</h4>
									<p>Efficiently manage your HR tasks with Mparchi's HRMS. Handle employee records, track attendance, and manage payroll seamlessly. Simplify your HR processes and keep everything organized.
									</p>
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="fancy-feature-twentyNine lg-container mt-90 pt-20 md-mt-70" id="p-tour">
				<div class="container">
					<div class="block-style-thirty bottom-border pb-80 mb-170 md-pb-40 md-mb-90">
						<div class="row">
							<div class="col-xl-5 col-lg-6">
								<div class="text-block md-pb-60">
									<div class="feature">CLICK & UPLOAD</div>
									<div class="title-style-nine mb-60 md-mb-40">
										<h3>Manage All Elements Effortlessly: <strong class="text-color1">Mparchi</strong> Makes It Simple.</h3>
									</div>
									<ul class="feature-list-one">
										<li>
											<img src="assets/images/icon/157.svg" alt="" class="icon" />
											<strong>Upload Company Logo</strong>
											<span>Upload your company logo quickly and easily. Customize your Mparchi experience with a personal touch.</span>
										</li>
										<li>
											<img src="assets/images/icon/158.svg" alt="" class="icon" />
											<strong>Create Custom Field</strong>
											<span>Tailor Your Mparchi Experience to Fit Your Needs. Simple, flexible, and just for you.</span>
										</li>
										<li>
											<img src="assets/images/icon/159.svg" alt="" class="icon" />
											<strong>Custom Attribute</strong>
											<span>Make Mparchi Work Just for You. Easy and adaptable.</span>
										</li>
										<li><a href="#" class="theme-btn-thirteen mt-20">Learn More</a></li>
									</ul>
								</div>
							</div>
							<div class="col-xl-7 col-lg-6">
								<img src="assets/images/screen_35.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
     
      <TailoredSection/> 
      
      <Testimonial/> 
    

    </>
  );
};

export default Home;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Pages/Home/Home";
import About from './Components/Pages/About/About';
import Contact from './Components/Pages/contact/Contact';
import Pricing from './Components/Pages/Pricing/Pricing';
import Privacy_Policy from './Components/Pages/Privacy_Policy';
import Terms_and_conditions from './Components/Pages/Terms_and_conditions';
import Terms_of_use from './Components/Pages/Terms_of_use';
import All_features from './Components/Pages/All_features';
import How_it_work from './Components/Pages/How_it_work';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our-price" element={<Pricing />} />
          <Route path='/privacy-policy' element={<Privacy_Policy />} />
          <Route path='/terms-and-conditions' element={<Terms_and_conditions />} />
          <Route path='/terms-of-use' element={<Terms_of_use />} />
          <Route path='/features' element={<All_features />} />
          <Route path='/how-it-work' element={<How_it_work />} />
          <Route path="/contact" element={<Contact />} />

        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;

const ContactForm = () => {
    return (
        <>
            <div class="contact-style-two">
                <div class="container">
                    <div class="form-style-classic mt-100 mb-100 md-mt-80">
                        <form action="#" id="contact-form" data-toggle="validator">
                            <div class="messages"></div>
                            <div class="row controls">
                                <div class="col-md-6" data-aos="fade-right" data-aos-duration="1200">
                                    <div class="input-group-meta form-group mb-60">
                                        <label>Name</label>
                                        <input type="text" placeholder="Enter Your Name" name="name" required="required" data-error="Name is required" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-md-6" data-aos="fade-left" data-aos-duration="1200">
                                    <div class="input-group-meta form-group mb-60">
                                        <label>Mobile No.</label>
                                        <input type="text" placeholder="Enter Your Mobile Number" name="mobile" required="required" data-error="Mobile no. is required." />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="input-group-meta form-group mb-60">
                                        <label>Email Address</label>
                                        <input type="email" placeholder="Enter Your Email Id" name="email" required="required" data-error="Valid email is required." />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
                                    <div class="input-group-meta form-group lg mb-40">
                                        <label>Your Message</label>
                                        <textarea placeholder="your message here.." name="message" required="required" data-error="Please,leave us a message."></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div class="col-12" data-aos="fade-up" data-aos-duration="1200"><button class="theme-btn-two">Send Message</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactForm
import React, { useState } from 'react';
import { Link } from 'react-router-dom'

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const showMenu = () => {
    setIsActive(!isActive);
  };
  const ShowDropdown = () => {
    setIsDropdown(!isDropdown)
  }

  const handleSetActive = (index) => {
    setActiveIndex(index)
  }

  return (
    <>
      <div className="theme-main-menu sticky-menu theme-menu-three">
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="" />
            </Link>
          </div>

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="nav-container">
              <button className="navbar-toggler" onClick={showMenu} >
                <span></span>
              </button>

              <div className={isActive ? 'navbar-collapse collapse show' : 'navbar-collapse collapse'} id="navbarSupportedContent">
                <div className="d-lg-flex justify-content-between align-items-center">
                  <ul className="navbar-nav">
                    <li className={activeIndex === 0 ? 'nav-item position-static active' : 'nav-item position-static'} onClick={() => handleSetActive(0)}>
                      <Link className="nav-link " to="/" data-toggle="dropdown">
                        Home
                      </Link>
                    </li>
                    <li className={activeIndex === 1 ? 'nav-item position-static active' : 'nav-item position-static'} onClick={() => handleSetActive(1)}>
                      <Link className="nav-link " to="/about" data-toggle="dropdown">
                        About
                      </Link>
                    </li>
                    <li className={activeIndex === 5 ? 'nav-item position-static active' : 'nav-item position-static'} onClick={() => handleSetActive(1)}>
                      <Link className="nav-link " to="/features" data-toggle="dropdown">
                      Features
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown mega-dropdown-md" onClick={ShowDropdown}>
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        data-toggle="dropdown"
                      >
                        Features
                      </Link>
                      <ul className={isDropdown ? 'dropdown-menu show' : 'dropdown-menu'}>
                        <li>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="menu-column">
                                <h6 className="mega-menu-title">Core Features</h6>
                                <ul className="mega-dropdown-list">
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Quotesmenu{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Invoicingmenu{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Sales orders
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Billsmenu{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Purchase orders{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Projectsmenu{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Banking{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Inventorymenu{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Expenses{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Documentsmenu{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Reporting{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      Online Paymentsmenu{" "}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="menu-column">
                                <h6 className="mega-menu-title">Compliance</h6>
                                <ul className="mega-dropdown-list">
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      GST Filingmenu
                                    </a>
                                  </li>
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      E-Invoicing
                                    </a>
                                  </li>
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      Audit Trailmenu
                                    </a>
                                  </li>
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      Document management
                                    </a>
                                  </li>
                                </ul>
                                <h6 className="mega-menu-title">
                                  Effortless Accounting
                                </h6>
                                <ul className="mega-dropdown-list">
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      Mobile Accountingmenu
                                    </a>
                                  </li>
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      MNB Ecosystem
                                    </a>
                                  </li>
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      Collaboration & Portal Management
                                    </a>
                                  </li>
                                  <li>
                                    <a to="#" className="dropdown-item">
                                      Smart Automationsmenu
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li> */}
                    <li className={activeIndex === 2 ? 'nav-item position-static active' : 'nav-item position-static'} onClick={() => handleSetActive(2)}>
                      <Link className="nav-link " to="/how-it-work" data-toggle="dropdown">
                        How It Work
                      </Link>
                    </li>
                    <li className={activeIndex === 3 ? 'nav-item position-static active' : 'nav-item position-static'} onClick={() => handleSetActive(3)}>
                      <Link className="nav-link " to="/our-price" data-toggle="dropdown">
                        Our Price
                      </Link>
                    </li>
                    <li className={activeIndex === 4 ? 'nav-item position-static active' : 'nav-item position-static'} onClick={() => handleSetActive(4)}>
                      <Link className="nav-link " to="/contact" data-toggle="dropdown">
                        Contact
                      </Link>
                    </li>
                  </ul>

                  <ul className="right-widget user-login-button d-flex align-items-center justify-content-center">
                  <li>
                  <Link className="signIn-action d-flex align-items-center" to="https://login.mparchi.com/">
									<img src="assets/images/icon/120.svg" alt="" />
									<span>Login</span>
                  </Link>
                  </li>
                    <li>
                    <Link className="signUp-action d-flex align-items-center" to="https://login.mparchi.com/signup">                      
                        <span>Getting Started</span>
                        </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
export default Header;
